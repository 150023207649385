<script setup lang="ts"></script>

<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
* {
  font-family: "Roboto", sans-serif;
}
</style>
